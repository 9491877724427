.title-container {
    /* width: 100%; */
    /* height: 3rem; */
    /* background-color: #05386b; */
    color: #05386b;
    margin-top: 7rem;
    /* text-align: center; */
}

.title-container h1 {
    font-weight: 500;
    text-align: center;
}

@media only screen and (max-width: 720px) {

    .title-container {
        margin-top: 6rem;
    }

    .title-container h1 {
        font-size: 2rem;
    }
}