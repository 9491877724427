.legal {
    background-color: #fff;
    padding-top: 7rem;
    padding-bottom: 10rem;
    font-family: Arial, Helvetica, sans-serif;
}

.legal p {
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 10px;
    width: 90%;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
}

.legal .legal-point {
    font-size: large;
    font-weight: 600;
}

