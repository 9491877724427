.social-share {
    position: fixed;
    left: 0;
    top: 50%;
   height: 100%;
   width: auto;
   padding-right: 10px;

   z-index: 20;
}

