.not-found {
  height: 100vh;
  width: 100vw;
  background-color: #edf5e1;
}

.not-found h1 {
  color: #05386b;
  font-weight: bolder;
}
