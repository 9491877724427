nav {
  z-index: 10;
}

.navbarItems {
  background-color: transparent;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
}

.navbarItems.active {
  background-color: #05386b;
}

.navbar-logo {
  color: #05386b;
  text-decoration: none;
  justify-self: start;
  margin-left: 20px;
  font-size: 38px;
  font-weight: 700;
  
}



.navbar-logo.active {
  color: #5cdb95;
}

.navbar-logo .p-member {
  position: relative;
  font-size: 0.9rem;
  font-weight: 900;
  color: #FFA500;
  top: 13px;
  left: -70px;
  
  
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.fa-bars {
  color: #05386b;
}

.nav-links {
  color: #05386b;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 18px;
  
}

.nav-links.active {
  color: #5cdb95;
}

.nav-links:hover {
  color: #edf5e1;
}

.nav-links-mobile {
  color: #edf5e1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background-color: #05386b;
  border-radius: 4px;
  margin-left: 5px;
}

.nav-links-mobile.active {
  background-color: #5cdb95;
  color: #05386b;
}

.nav-links-mobile:hover {
  background-color: #edf5e1;
  color: #05386b;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {


  .navbar-logo {
    margin-left: -5px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: #05386b;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    display: flex;
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #5cdb95;
  }

  .nav-links:hover {
    color: #edf5e1;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(20%, 20%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-bars.active {
    color: #5cdb95;
  }

  .fa-times {
    color: #05386b;
    font-size: 2rem;
  }

  .fa-times.active {
    color: #5cdb95;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background-color: #5cdb95;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background-color: #edf5e1;
    color: #05386b;
    transition: 250ms;
  }
}


