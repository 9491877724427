
  
  
  .n-header {
    margin-bottom: 35px;
    margin-left: 33px;
  }
  
  .n-header > h1 {
   margin-top: 10vh;
    color: #163269;
    font-weight: 700;
  }
  


  
  .news .read-n {
    color: #3db166;
    text-decoration: none;
    font-size: 14px;
  }
  
  .n-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    justify-content: center;
    margin: 0 30px;
    overflow: hidden;
  }

  .lec-search-container input {
    height: 50px;
    width: 80vw;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .lec-search-container select {
    height: 30px;
    width: 80vw;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
 
  
 .n-img > img {
    width: 75%;
    height: auto;
    border-radius: 1rem;
    border-top-left-radius:1.4rem;
    border-top-right-radius:1.4rem;



    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
    transform: scale(1);
    transition: transform 0.5s ease;
    cursor: pointer;
  }
  
 .n-img > img:hover {
    transform: scale(1.1);
  }
  
  .n-content {
    margin-top: 20px;
    margin-left: 3px;
  }
  
.lock-icon {
  font-size: 20px;
  display: flex;
justify-content: right;

 
}
  
  .n-title {
    font-size: 19px;
    color: #192f59;
    margin-top: -20px;
    font-weight: 500;

  }

  .article-content {
    margin-top: 15vh;
  }

  .article-content > img {
    width: 70vw;
    height: 40vw;
    padding-bottom: 5vh;
  }

  .article-content .article-title {
    font-weight: 500;
    color: #192f59;
  }

  .article-content > p {
    width: 70vw;
    margin: auto;
    text-align: left;
    padding-bottom: 20vh;
    font-weight: 400;

  }
  
  @media (max-width: 720px) {
    .n-img > img {
      width: 80vw;
      height: auto;
      
  
    
    }
    .n-box {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
      justify-items: center;
      margin: 0 30px;
      overflow: hidden;
    }

    .article-content {
      margin-top: 15vh;
      margin-left: 10px;
    }

    .article-content > img {
      width: 90vw;
      height: 60vw;
      padding-bottom: 5vh;
    }

    .article-content > p {
      width: 90vw;
      margin: auto;
      text-align: left;
      padding-bottom: 20vh;
      font-weight: 400;
  
    }

    .article-content h1 {
      font-size: 25px;
      font-weight: 500;
    }
  }
  
  @media (min-width: 1300px) {
    .lec-search-container input {
    
      width: 50vw;
     
    }

    .lec-search-container select {
      width: 50vw;
    }
    .news {
      margin-top: 70px;
      margin-left: 200px;
      margin-right: 200px;
    }
  
    .n-header h1 {
      font-size: 28px;
    }
  
    .news .read-n {
      font-size: 16px;
    }
  
    .n-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px;
      justify-content: center;
      grid-column-gap: 5%;
      overflow: hidden;
    }
  
    .n-date {
      font-size: 14px;
    }
  
    .n-title {
      font-size: 17px;
    }
  
    .n-header {
      margin-bottom: 35px;
      margin-left: 33px;
    }
  
    .n-header h1::after {
      content: " ";
      position: absolute;
      width: 85%;
      height: 0.15rem;
      background: #e6e6e6;
      top: 16px;
      left: 252px;
    }
  }