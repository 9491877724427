textarea {
  width: 450px;
  margin: 15px;
  padding: 14px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
}

@media only screen and (max-width: 720px) {
  .form textarea {
    width: 320px;
    font-size: 18px;
  }
}
