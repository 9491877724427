.oir {
  display: grid;
  grid-template-rows: 60px auto;
  width: auto;
  height: auto;
}

.oir_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  background-color: #5cdb95;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1;
}

.oir_header p {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  padding: 13px 15px;
  margin: 10px;
}

.oir_body {
  padding-top: 100px;
  padding-left: 30px;
  padding-bottom: 50vh;
  background-color: #edf5e1;
  
}

.oir_body .question {
  color: #05386b;
  font-size: 28px;
  font-weight: 500;
  text-align: left;
}

.oir_body .option_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: center;
  list-style: none;
  margin: 40px 0;
}

.oir_body .option_group .option {
  display: block;
  width: 300px;
  text-align: left;
  background-color: #05386b;
  color: #fff;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.4s all;
  border: 1px solid #05386b;
}

.oir_body .option_group .option_test {
  display: block;
  width: 300px;
  text-align: left;
  background-color: transparent;
  color: #05386b;
  font-weight: 600;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.4s all;
  border: 3px solid #05386b;
}

.oir_body .option_group .option_test:hover {
  background-color: #05386b;
  color: #edf5e1;
}

.oir_body .option_group .option_test:active {
  background-color: #05386b;
  color: #edf5e1;
}

.oir_body .option_group .test_select {
  display: block;
  width: 300px;
  text-align: left;
  font-weight: 600;
  color: #edf5e1;
  background-color: #05386b;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  transition: 0.4s all;
}

.oir_body .option_group .select {
  display: block;
  width: 300px;
  text-align: left;
  background-color: #5cdb95;
  color: #fff;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.4s all;
}

.oir_body .option_group .wrong {
  display: block;
  width: 300px;
  text-align: left;
  background-color: #ff0033;
  color: #fff;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.4s all;
}

.oir_body .option_group .option_active {
  display: block;
  width: 300px;
  text-align: left;
  background-color: #05386b;
  color: #fff;
  margin-bottom: 15px;
  padding: 12px 30px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.4s all;
}

.btn {
  display: flex;
}

.btn_next {
  background-color: #5cdb95;
  padding: 13px 28px;
  border-radius: 4px;
  margin: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
}

.btn_next:hover {
  background-color: #05386b;
  cursor: pointer;
  color: #fff;
  border: none;
}

.btn_next:active {
  background-color: #05386b;
}

.btn_exit {
  background-color: #edf5e1;
  padding: 13px 28px;
  border-radius: 4px;
  margin: 10px;
  color: #05386b;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  border: none;
  outline: none;
}

.btn_exit:hover {
  background-color: #05386b;
  cursor: pointer;
  color: #fff;
  border: none;
}

.response_btn {
  background-color: #5cdb95;
  padding: 13px 25px;
  border-radius: 4px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  border: none;
  outline: none;
}

.response_btn:hover {
  background-color: #05386b;
  color: #fff;
}

.OIRResponse {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0rem;
  padding-top: 10vh;
  padding-bottom: 0vw;
  place-items: center;
  width: 100%;
  height: auto;
  margin: auto;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
}

.OIRResponse p {
  font-size: 21px;
  width: 70vw;
  text-align: justify;
  font-weight: 400;
  color: #05386b;
}

.oirTestResBody {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0rem;
  padding-top: 5vh;
  padding-bottom: 0vw;
  place-items: center;
  width: 100%;
  height: auto;
  margin: auto;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  background-color: #edf5e1;
}

.testResponse {
  width: 90vw;
}

.oirTestResBody .questionTestResponse {
  color: #05386b;
  font-size: 25px;
  font-weight: 500;
  text-align: left;
}

.oirTestResBody .opsTestResponse {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 30px;
  color: #05386b;
  font-size: 21px;
  font-weight: normal;
  text-align: left;
}

.oirTestResBody .ansTestResponse {
  padding: 0;
  margin: 0;
  color: #05386b;
  font-size: 21px;
  font-weight: normal;
  text-align: left;
}

.oirTestResBody .expTestResponse {
  color: #05386b;
  font-size: 21px;
  font-weight: normal;
  text-align: left;
}

.optionGroupTestResponse {
  display: flex;
}

.optionGroupTestResponse p {
  padding: 0;
  margin: 0;
  font-size: 19px;
  color: #05386b;
  font-weight: 400;
}

.result_body {
  margin-top: 40px;
  margin-bottom: 20px;
}

.result_body p {
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: #05386b;
}

.middle p {
  position: relative;
  top: -20px;
  font-size: 28px;
}

.btns {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  position: absolute;
  bottom: 0;
  padding-bottom: 5rem;
}

.btns .home {
  background-color: #5cdb95;
  padding: 12px 25px;
  width: 100%;
  font-size: 1rem;
  border-radius: 4px;
  color: #fff;
}

.btns .home:hover {
  background-color: #05386b;
  color: #fff;
}

@media only screen And (max-width: 720px) {
  .oir {
    grid-template-rows: 50px auto;
  }

  .oir_body {
    padding-top: 30px;
    padding-left: 30px;
    background-color: #edf5e1;
    width: 100vw;
    height: auto;
  }

  .oir_body .question {
    color: #05386b;
    font-size: 21px;
    text-align: left;
    max-width: 95%;
    height: auto;
  }

  .op_bt {
    position: relative;
    top: -25px;
  }

  .oir_body .option_group {
    grid-template-columns: repeat(1, 1fr);
  }

  .oir_body .option_group .option {
    width: 270px;
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 8px;
    padding: 12px 30px;
  }

  .btn_next:hover {
    background-color: #5cdb95;
  }

  .oir_header p {
    color: #fff;
    font-size: 21px;
    padding: 20px;
    padding-top: 25px;
  }

  .response_btn {
    padding: 14px 19px;

    font-size: 16px;
    font-weight: 400;
  }

  .OIRResponse {
    padding-top: 0vh;
    padding-bottom: 0vw;
    background-color: #edf5e1;
  }

  .oirTestResBody {
    padding-top: 20px;
    background-color: #edf5e1;
  }

  .testResponse {
    width: 90vw;
  }

  .oirTestResBody .questionTestResponse {
    font-size: 21px;
    font-weight: 400;
    text-align: left;
  }

  .oirTestResBody .opsTestResponse {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    color: #05386b;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .oirTestResBody .ansTestResponse {
    padding: 0;
    margin: 0;
    color: #05386b;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  .oirTestResBody .expTestResponse {
    color: #05386b;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
}
