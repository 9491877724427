.instruction {
  display: grid;
  grid-template-rows: 60px auto;
  margin: auto;
  margin-top: 100px;
  width: 60vw;
  height: auto;
  background-color: #edf5e1;
  border-radius: 8px;
  box-shadow: -2px 10px 22px -16px rgb(0 0 0 / 75%);
}

.ins_header {
  background-color: #05386b;
  color: #edf5e1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ins_header h1 {
  font-size: 24px;
  margin-top: 12px;
  font-weight: 500;
}

.ins_body {
  margin-top: 40px;
  margin-left: 40px;
}

.ins_body p {
  text-align: left;
  font-size: 19px;
  color: #05386b;
}

.ins_footer {
  margin-bottom: 70px;
  padding-top: 20px;
}

@media only screen and (max-width: 720px) {
  .instruction {
    width: 95vw;
    height: auto;
    background-color: #edf5e1;
  }

  .ins_body {
    margin-top: 30px;
    margin-left: 25px;
  }
}
