.card {
  background-color: #fff;
  margin: 50px 50px;
  width: 150px;
  height: 150px;
  box-shadow: -2px 10px 22px -16px rgb(0 0 0 / 75%);
  border-radius: 4px;
}

img {
  height: 70px;
  width: 70px;
  padding-top: 10px;
  margin: 10px;
}
