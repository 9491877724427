.faculty {
  width: 100vw;
  height: auto;

}

.facultyBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  padding-top: 1rem;
  padding-bottom: 8rem;
  place-items: center;
  width: 100%;
  height: auto;
  margin: auto;
}

.facultyCard {
  width: 280px;
  overflow: hidden;
  box-shadow: 0px 0px 15px -15px;
  background-color: #edf5e1;
  border-radius: 5px;
  color: #05386b;
}

.facultyImg img {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

.facultyName {
  text-align: center;
  padding-top: 10px;
}

.facultyName h2 {
  font-weight: bold;
}

.facultyDesc {
  padding: 8px;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 1300px) {
  .facultyCard {
    width: 350px;
  }

  .facultyImg img {
    height: 260px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 720px) {
  .facultyBody {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important; */
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 720px) {
  .facultyBody {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important; */
    grid-template-columns: repeat(1, 1fr);
  }
}
